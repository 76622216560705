<template>
  <BodySimple>
    <template #header>
      <div class="flex items-center">
        <img src="@/assets/images/icons8-schedule-mail-96.png" class="h-20 ml-2" />
        <div class="text-xl font-bold">اضافه کردن تکرار پست</div>
      </div>
      <h2 class="text-xl font-bold mt-3">
        <Icon name="Group"></Icon>
        {{ group.name }}
      </h2>
      <div class="mt-3 text-right" dir="ltr">{{ group.gid }}</div>
    </template>
    <template #default>
      <ErrorBox :errors="errors" class="mb-5" />
      <form action="" @submit.prevent="submit()">
        <div class="grid sm:grid-cols-3 gap-3">
          <MnrText
            v-model="inputs.name"
            title="نام این زمان"
            placeholder="یک نام وارد کنید"
          />
          <MnrSelect
            v-model="inputs.forward.value"
            :options="inputs.forward.options"
            title="نوع ارسال"
            placeholder="انتخاب کنید"
          />
          <MnrSelect
            v-model="inputs.status.value"
            :options="inputs.status.options"
            title="وضعیت"
            placeholder="انتخاب کنید"
          />
        </div>
        <div class="grid sm:grid-cols-3 gap-3 mt-10">
          <MnrSelect
            v-model="inputs.send.value"
            :options="inputs.send.options"
            title="زمانبندی"
            placeholder="انتخاب کنید"
          />
          <MnrText
            v-model="inputs.start"
            v-show="inputs.send.value == 'period'"
            type="time"
            title="شروع بعدی"
            hint="اگرو شروع بعدی قبل از زمان حاظر باشد روز بعد شروع می‌شود"
          />
        </div>
        <div class="mt-3" v-show="inputs.send.value == 'period'">
          <h2 class="text-lg font-bold">دوره تناوب فاصله بین پست ها</h2>
          <div class="grid grid-cols-3 gap-3 mt-2">
            <MnrNumber
              v-model="inputs.period.min"
              hint="دقیقه"
              before="دقیقه"
              placeholder="0"
              :min="0"
              :max="59"
            ></MnrNumber>
            <MnrNumber
              v-model="inputs.period.hour"
              hint="ساعت"
              before="ساعت"
              placeholder="0"
              :min="0"
              :max="59"
            ></MnrNumber>
            <MnrNumber
              v-model="inputs.period.day"
              hint="روز"
              before="روز"
              placeholder="0"
              :min="0"
            ></MnrNumber>
          </div>
        </div>
        <div v-show="inputs.send.value == 'time'">
          <div class="grid sm:grid-cols-3 gap-3 mt-10">
            <div
              v-for="(time, index) in inputs.times"
              :key="index"
              class="bg-blueGray-100 border border-blueGray-200 rounded-md p-2 gap-2 flex"
            >
              <MnrText v-model="time.value" class="w-full" type="time" />
              <Button
                class="inline-flex text-sm lg:text-lg"
                color="red"
                @click="inputs.times.splice(index, 1)"
              >
                ×
              </Button>
            </div>
          </div>
          <Button
            class="mt-3 inline-flex text-sm h-11 lg:text-lg"
            color="teal"
            @click="inputs.times.push({ value: '' })"
          >
            اضافه کردن
          </Button>
        </div>
        <div class="sm:w-60 w-full mt-3 mx-auto">
          <Button type="submit" class="mt-3 text-sm h-11 lg:text-lg" color="sky">
            ثبت
          </Button>
        </div>
      </form>
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
import Icon from "@/components/icon/Icon.vue";
import MnrText from "@/components/mnr/MnrText.vue";
import MnrSelect from "@/components/mnr/MnrSelect.vue";
import Button from "@/components/Button.vue";
import ErrorBox from "@/components/ErrorBox.vue";
import MnrNumber from "@/components/mnr/MnrNumber.vue";

export default {
  components: {
    BodySimple,
    Icon,
    MnrText,
    MnrSelect,
    Button,
    ErrorBox,
    MnrNumber,
  },
  data() {
    return {
      group: {},
      errors: [],
      inputs: {
        name: "",
        forward: {
          value: 0,
          options: [
            {
              text: "فوروارد",
              value: 1,
            },
            {
              text: "کپی",
              value: 0,
            },
          ],
        },
        status: {
          value: 1,
          options: [
            {
              text: "فعال",
              value: 1,
            },
            {
              text: "غیر فعال",
              value: 0,
            },
          ],
        },
        send: {
          value: "period",
          options: [
            {
              text: "دوره‌ای",
              value: "period",
            },
            {
              text: "زمانی",
              value: "time",
            },
          ],
        },
        start: null,
        period: {
          min: 0,
          hour: 8,
          day: 0,
        },
        times: [{ value: "" }, { value: "" }, { value: "" }],
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      let $this = this;
      // $this.firstLoading = true;
      $this.$axios
        .get("/api/group/" + $this.$route.params.groupId, {
          params: {
            id: $this.$store.state.user.id,
            // date: $this.date,
          },
        })
        .then(function (response) {
          // console.log(response);
          $this.group = response.data.group;
          // $this.users = response.data.users;
          // $this.date = response.data.date;
        })
        .catch(function (error) {
          // console.log(error);
        })
        .then(function () {
          $this.firstLoading = false;
          $this.sending = false;
        });
    },
    submit() {
      let $this = this;
      $this.errors = [];
      // $this.firstLoading = true;
      $this.$axios
        .post("/api/group-posts/" + $this.$route.params.groupId, {
          id: $this.$store.state.user.id,
          inputs: $this.inputs,
          // search: $this.searchTools,
          // date: $this.date,
        })
        .then(function (response) {
          // console.log(response);
          $this.$router.push({
            name: "GroupOwnerSettingRepeat",
            params: {
              id: $this.$store.state.user.id,
              groupId: $this.$route.params.groupId,
            },
          });
        })
        .catch(function (error) {
          // console.log(error);
          $this.errors = error.data;
        })
        .then(function () {
          $this.firstLoading = false;
          $this.sending = false;
        });
    },
  },
};
</script>

<style></style>
